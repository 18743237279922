import * as React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ArrowRight, Place } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { navigate } from '../../../utils/dom';
import { formatNumber } from '../../../utils/number';

// ========== TYPES ========== //

export interface MyPropertyCardMBProps {
  orgId: number;
  property: {
    developmentId: number;
    title: string;
    postcode: string | null;
    address: string | null;
    addressLine2: string | null;
    city: string | null;
    region: string | null;
    localAuthority: string | null;
    country: string | null;
    imgThumbnailId: string | null;
    developmentAnalytic?: {
      developmentViewCount7day: number | null;
      developmentAverageEngagementTime7day: number | null;
    };
  };
  imgThumbnailSrc?: string;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customCard: {
      boxShadow: 'none',
      borderRadius: 7,
      marginTop: theme.spacing(2),
    },

    cardTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.fontWeightSemiBold,
      textTransform: 'uppercase',
    },

    cardSubTitle: {
      fontSize: theme.typography.subtitle2.fontSize,
    },

    cardPrice: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },

    media: {
      height: 140,
    },

    mediaTip: {
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      color: '#fff',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      height: 20,
    },

    icon: {
      color: theme.palette.gray.dark,
      fontSize: theme.typography.h6.fontSize,
      verticalAlign: 'middle',
      marginLeft: -4,
    },
    upIcon: {
      transform: 'rotateZ(-90deg)',
      color: '#3258A8',
      fontSize: theme.typography.h6.fontSize,
    },
    downIcon: {
      transform: 'rotateZ(90deg)',
      color: theme.palette.primary.main,
      fontSize: theme.typography.h6.fontSize,
    },
    textIcon: {
      color: theme.palette.gray.light,
      verticalAlign: 'middle',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.subtitle2.fontSize,
      margin: theme.spacing(1.5, 0),
      '& span': {
        fontSize: theme.typography.subtitle2.fontSize,
      },
    },
    analyticsNumber: { fontWeight: theme.typography.fontWeightSemiBold },
    textAddress: {
      color: theme.palette.gray.light1,
      verticalAlign: 'middle',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.subtitle2.fontSize,
    },
    textReport: {
      color: theme.palette.gray.light1,
      verticalAlign: 'middle',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.subtitle2.fontSize,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      margin: 0,
    },
    alignRow: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.gray.dark,
      margin: 0,
    },
    arrowIcon: {
      color: theme.palette.gray.dark,
      margin: '-4px 0 -4px -8px',
    },
  })
);

// ========== COMPONENT ========== //

export default function MyPropertyCardMB({
  orgId,
  property,
  imgThumbnailSrc,
}: MyPropertyCardMBProps) {
  const classes = useStyles();

  const addressWithPostcode = [property.address, property.postcode].join(', ');

  const handleCardClick = () => {
    void navigate(
      `/my-account/property/edit/${orgId}/${property.developmentId}/basic-information`
    );
  };

  const viewCount = property.developmentAnalytic?.developmentViewCount7day;
  const showViewCount = viewCount !== null && viewCount !== undefined;

  const averageEngagementTime =
    property.developmentAnalytic?.developmentAverageEngagementTime7day;
  const showAverageEngagementTime =
    averageEngagementTime !== null && averageEngagementTime !== undefined;

  return (
    <Card className={classes.customCard}>
      <CardActionArea onClick={handleCardClick}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              {imgThumbnailSrc ? (
                <CardMedia
                  className={classes.media}
                  image={imgThumbnailSrc}
                  title={property.title}
                />
              ) : (
                <Skeleton variant="rect" width="auto" height={140} />
              )}
              {/* <div className={classes.mediaTip}>30-day Rent Free</div> */}
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h2" className={classes.cardTitle}>
                {property.title}
              </Typography>
              {/*<Typography variant="subtitle1" className={classes.cardSubTitle}>*/}
              {/*  From{' '}*/}
              {/*  <span className={classes.cardPrice}>*/}
              {/*    {formatMoney(property?.rent_pcm, 'GBP', 0)}*/}
              {/*  </span>*/}
              {/*  /month*/}
              {/*</Typography>*/}
              <Typography className={classes.textIcon}>
                <Place className={classes.icon} />{' '}
                <span className={classes.textAddress}>
                  {addressWithPostcode}
                </span>
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <p className={classes.alignRow}>
                    <ArrowRight className={classes.arrowIcon} /> 7-day views
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.textReport}>
                    {showViewCount ? (
                      <span className={classes.analyticsNumber}>
                        {viewCount}
                      </span>
                    ) : (
                      'Coming soon'
                    )}
                    {/*<ForwardIcon className={classes.upIcon} />*/}
                    {/*<span className={classes.analyticsNumber}>29%)</span>*/}
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <p className={classes.alignRow}>
                    <ArrowRight className={classes.arrowIcon} /> 7-day lead{' '}
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.textReport}>
                    {/*<span className={classes.analyticsNumber}>59 (</span>*/}
                    {/*<ForwardIcon className={classes.downIcon} />*/}
                    {/*<span className={classes.analyticsNumber}>32%)</span>*/}
                    Coming soon
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <p className={classes.alignRow}>
                    <ArrowRight className={classes.arrowIcon} /> Avg visit time
                  </p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.textReport}>
                    {showAverageEngagementTime ? (
                      <span className={classes.analyticsNumber}>
                        {`${formatNumber(averageEngagementTime, {
                          decimalPlaces: 2,
                        })}s`}
                      </span>
                    ) : (
                      'Coming soon'
                    )}
                    {/*<span className={classes.analyticsNumber}>52 (</span>*/}
                    {/*<ForwardIcon className={classes.upIcon} />*/}
                    {/*<span className={classes.analyticsNumber}>16%)</span>*/}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
