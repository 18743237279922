import React from 'react';
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Select } from '../../atoms';
import { MyPropertiesSortType } from './MyProperties';

interface MyPropertiesSorterProps {
  totalOfProperties: number | undefined;
  sortType: MyPropertiesSortType;
  updateSortType: (newSort: MyPropertiesSortType) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noBorderSelect: {
      borderRadius: 0,
      '& > .MuiSelect-select': {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1.25, 4, 1.25, 2),
        },
      },
      '& > .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        borderColor: 'transparent',
      },
      '&:hover > .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
    noBorderDropdown: {
      borderColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        marginTop: 44,
      },
    },
    flex: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    totalCount: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: 600,
    },
  })
);

export default function MyPropertiesSorter({
  totalOfProperties,
  sortType,
  updateSortType,
}: MyPropertiesSorterProps) {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} className={classes.totalCount}>
        <Hidden lgUp>
          {totalOfProperties
            ? `${totalOfProperties} properties`
            : 'No property found.'}
        </Hidden>
      </Grid>

      <Grid item xs={6} className={classes.flex}>
        <Select
          id="sort-by"
          htmlFor="sort-by"
          className={classes.noBorderSelect}
          dropdownClassName={classes.noBorderDropdown}
          value={sortType}
          onChange={(e) =>
            updateSortType(e.target.value as MyPropertiesSortType)
          }
          options={[
            // {
            //   value: MyPropertiesSortType.NEWEST_UPLOAD,
            //   label: MyPropertiesSortType.NEWEST_UPLOAD,
            // },
            {
              value: MyPropertiesSortType.NAME_A_Z,
              label: MyPropertiesSortType.NAME_A_Z,
            },

            // TODO: enable when we know the user is allowed to view these details
            // {
            //   value: MyPropertiesSortType.MOST_7_DAY_VIEW,
            //   label: MyPropertiesSortType.MOST_7_DAY_VIEW,
            // },
            // { value: MyPropertiesSortType.MOST_LEAD, label: MyPropertiesSortType.MOST_LEAD },
            // {
            //   value: MyPropertiesSortType.MOST_AVERAGE_VIEW,
            //   label: MyPropertiesSortType.MOST_AVERAGE_VIEW,
            // },
          ]}
        />
      </Grid>
    </Grid>
  );
}
