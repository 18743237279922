import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { combineQueryParams } from '../../utils/url';
import { OrgPvtPropertyV2 } from './types';

export interface OrgPvtPropertiesFetcherResponse {
  authToken: string;
  orgId: number;
}

export interface UseOrgPvtPropertiesReturn {
  data: OrgPvtPropertyV2[];
  meta: { count: number; totalCount: number };
}

export enum OrgPvtPropertiesSortType {
  CREATION_DATE = 'creation-date',
  DEVELOPMENT_TITLE = 'development-title',
  '7DAY_VIEW' = '7day-view',
  AVERAGE_VIEWING_TIME = 'average-viewing-time',
  LEAD = 'lead',
}

const DEFAULT_ITEMS_PER_PAGE = 20;

export async function orgPvtPropertiesFetcher(
  url: string,
  { authToken, orgId }: OrgPvtPropertiesFetcherResponse
): Promise<UseOrgPvtPropertiesReturn> {
  const headers: RequestInit['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'BTR-Organization': `${orgId}`,
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(url, {
    headers,
    credentials: 'include',
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json() as Promise<UseOrgPvtPropertiesReturn>;
}

export default function useOrgPvtProperties({
  authToken,
  page,
  itemsPerPage,
  sort,
  sortDirection,
  orgId,
  queryConfig,
}: {
  authToken: string;
  page: number;
  itemsPerPage?: number;
  sort?: OrgPvtPropertiesSortType;
  sortDirection?: 'asc' | 'desc';
  orgId: number;
  queryConfig?: UseQueryOptions<UseOrgPvtPropertiesReturn>;
}) {
  const queryParamsStr = combineQueryParams({
    page,
    sort,
    sortDirection,
    itemsPerPage: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
  });

  const url = `${process.env.GATSBY_API_HOST}/api/orgPvt/properties?${queryParamsStr}`;

  return useQuery<UseOrgPvtPropertiesReturn, Error>(
    [url, { authToken, orgId }],
    () => orgPvtPropertiesFetcher(url, { authToken, orgId }),
    queryConfig
  );
}
