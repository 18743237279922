import { ImageData } from '../property/types';

export interface OrgInDb {
  organization_id: number;
  title?: string;
}

export interface OrgPvtProperty {
  development_id: number;
  title: string;
  address: string;
  postcode: string | null;
  img_ids: string[] | null;
  img_data: ImageData[] | null;
  flat_data_arr: {
    flat_id: number;
    rent_pcm: number | null;
    bedrooms_count: number;
  }[];
}

export interface OrgPvtPropertyV2 {
  developmentId: number;
  title: string;
  postcode: string | null;
  address: string | null;
  addressLine2: string | null;
  city: string | null;
  region: string | null;
  localAuthority: string | null;
  country: string | null;
  imgThumbnailId: string | null;
  imgThumbnail?: {
    orgimageId: string;
    srcUrl: string;
    category: string | null;
    fileType: string;
  } | null;
  developmentAnalytic?: {
    developmentViewCount7day: number | null;
    developmentAverageEngagementTime7day: number | null;
  };
  mediasCount?: {
    developmentMedias: Record<OrgFileCategory, number>;
    flatMedias: Record<string, Record<OrgFileCategory, number>>;
  };
}

export enum OrgFileCategory {
  UNCATEGORIZED = 'UNCATEGORIZED',

  DEVELOPMENT_VIDEO = 'DEVELOPMENT_VIDEO',
  FLAT_IMAGE = 'FLAT_IMAGE',
  DEVELOPMENT_IMAGE = 'DEVELOPMENT_IMAGE',
  DEVELOPMENT_BROCHURE = 'DEVELOPMENT_BROCHURE',
  DEVELOPMENT_VIDEO_THUMBNAIL_IMAGE = 'VIDEO_THUMBNAIL_IMAGE',
  VIDEO_THUMBNAIL = 'VIDEO_THUMBNAIL',

  FLOOR_PLAN_IMAGE = 'FLOOR_PLAN_IMAGE',
  FLAT_VIDEO = 'FLAT_VIDEO',
  FLAT_VIDEO_THUMBNAIL_IMAGE = 'FLAT_VIDEO_THUMBNAIL_IMAGE',

  // TODO: remove
  PROPERTY_IMAGE = 'PROPERTY_IMAGE',
}

export enum OrgFileStatus {
  // An empty string is also possible, for now...

  UPLOADING_VIDEO = 'UPLOADING_VIDEO',
  VIDEO_WITH_THUMBNAIL = 'VIDEO_WITH_THUMBNAIL',
}

export enum OrgFileType {
  VIDEO = 'video',
  IMAGE = 'image',
}

// An interface that describes generic data stored in our storage for each
// organization
export interface OrgFile {
  orgimages_id: string; // UUID

  organization_id: number;
  src_url: string;
  srcUrl: string;
  src_video_url?: string;
  original_name: string;

  file_type: OrgFileType;
  category: OrgFileCategory;
  description?: string;

  // Default to 10
  priority: number;

  // For now, 'status' could be empty
  status: string | null | OrgFileStatus;
}

export interface OrgFileV2 {
  orgimageId: string;
  organizationId: number;
  srcUrl: string;
  originalName: string;
  category: OrgFileCategory;
  description: string | null;
  priority: number;
  fileType: OrgFileType;
  status: string | null | OrgFileStatus; // For now, 'status' could be empty
}
