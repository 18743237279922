import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function PropertyImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="18.537" height="14.5" viewBox="0 0 18.537 14.5" {...props}>
      <g id="Group_646" data-name="Group 646" transform="translate(0.5 0.5)">
        <g id="Group_645" data-name="Group 645" transform="translate(0 0)">
          <g id="Group_644" data-name="Group 644" transform="translate(0 0)">
            <path
              id="Path_50"
              data-name="Path 50"
              d="M16.076,72H1.461A1.541,1.541,0,0,0,0,73.607V83.893A1.541,1.541,0,0,0,1.461,85.5H16.076a1.234,1.234,0,0,0,.245-.023,1.567,1.567,0,0,0,1.217-1.584V73.607A1.541,1.541,0,0,0,16.076,72ZM1.461,84.857a.914.914,0,0,1-.873-.879l5.828-4.19,5.926,5.07Zm15.491-.964a.94.94,0,0,1-.734.95.725.725,0,0,1-.143.014H13.294L9.422,81.543,12.885,78.5l4.068,2.684Zm0-3.46-3.942-2.6a.271.271,0,0,0-.333.024L8.946,81.138l-2.337-2a.271.271,0,0,0-.339-.015L.585,83.212v-9.6a.924.924,0,0,1,.877-.964H16.076a.924.924,0,0,1,.877.964Z"
              transform="translate(0 -72)"
              stroke="rgba(0,0,0,0)"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_51"
              data-name="Path 51"
              d="M193.754,120a1.754,1.754,0,1,0,1.754,1.754A1.754,1.754,0,0,0,193.754,120Zm0,2.923a1.169,1.169,0,1,1,1.169-1.169A1.169,1.169,0,0,1,193.754,122.923Z"
              transform="translate(-184.985 -118.246)"
              stroke="rgba(0,0,0,0)"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
