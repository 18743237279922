import * as React from 'react';
import {
  Box,
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Layout from '../../components/layout/CommonLayout/Layout';
import SEO from '../../components/atoms/SEO';
import GenericPage from '../../components/layout/CommonLayout/GenericPage';
import MyProperties from '../../components/organisms/MyProperties/MyProperties';
import MyAccountSideBar from '../../components/pages/my-account/MyAccountSideBar/MyAccountSideBar';

import useMyUserDetails from '../../api/auth/me/useMyUserDetails';

import { useSignOut } from '../../hooks';
import useAuthToken from '../../store/auth/hooks/useAuthToken';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainCtn: {
      width: '100%',
      background: '#f3f3f3',
      padding: theme.spacing(7, 0),

      [theme.breakpoints.up('mm')]: {
        width: theme.breakpoints.values.mm,
      },
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2.5, 0),
      },
    },

    sectionHeader: {
      margin: `${theme.spacing(2)}px 0`,
    },
  })
);

// ========== COMPONENT ========== //

const MyAccountPage = () => {
  const classes = useStyles();

  const authToken = useAuthToken();
  const signOut = useSignOut();

  const { data: myUserDetails } = useMyUserDetails({
    authToken,
  });

  return (
    <Layout>
      <SEO title="Properties of my organisation" />
      <GenericPage>
        <Box className={classes.mainCtn}>
          <Container>
            <Grid container spacing={0}>
              <Hidden mdDown>
                <Grid item xs={12} lg={3}>
                  <MyAccountSideBar
                    userData={myUserDetails?.user}
                    onSignOutButtonClick={() => signOut()}
                  />
                </Grid>
              </Hidden>
              <Grid container item xs={12} lg={9}>
                {myUserDetails &&
                myUserDetails.user.organization_ids.length > 0 ? (
                  myUserDetails.user.organization_ids.map((orgId) => (
                    <MyProperties key={orgId} orgId={orgId} />
                  ))
                ) : (
                  <Typography>There is nothing here.</Typography>
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </GenericPage>
    </Layout>
  );
};

export default MyAccountPage;
