import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function PropertyVideoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="20.482" height="14.5" viewBox="0 0 20.482 14.5" {...props}>
      <path
        id="Path_52"
        data-name="Path 52"
        d="M19.28,88.2a.381.381,0,0,0-.409-.008L14.4,90.88V89.27A2.176,2.176,0,0,0,12.337,87H2.064A2.176,2.176,0,0,0,0,89.27V98.23a2.176,2.176,0,0,0,2.064,2.27H12.337A2.176,2.176,0,0,0,14.4,98.23V96.649l4.47,2.684a.381.381,0,0,0,.408-.008.465.465,0,0,0,.2-.39V88.594A.464.464,0,0,0,19.28,88.2Zm-5.7,10.027a1.306,1.306,0,0,1-1.238,1.362H2.064A1.306,1.306,0,0,1,.825,98.231V89.27a1.306,1.306,0,0,1,1.238-1.362H12.337a1.306,1.306,0,0,1,1.238,1.362Zm5.081-.061L14.4,95.615v-3.7l4.255-2.555Z"
        transform="translate(0.5 -86.5)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </SvgIcon>
  );
}
