import { OrgFileCategory } from '../../../api/orgs/types';

type mediaCountReturn = {
  totalImageCount: number;
  totalVideoCount: number;
  totalFloorCount: number;
};

export const getMediaCounts = (
  developmentMedias?: Record<string, number>,
  flatMedias?: Record<string, Record<string, number>>
): mediaCountReturn => {
  let totalImageCount = 0;
  let totalVideoCount = 0;
  let totalFloorCount = 0;

  if (developmentMedias) {
    const developmentMediaEntries = Object.entries(developmentMedias);
    const flatEntries = Object.entries(flatMedias ?? []);

    developmentMediaEntries.map(([mediaKey, count]) => {
      if (
        [OrgFileCategory.DEVELOPMENT_IMAGE].includes(
          mediaKey as OrgFileCategory
        )
      ) {
        totalImageCount += count;
      }

      if (
        [OrgFileCategory.DEVELOPMENT_VIDEO].includes(
          mediaKey as OrgFileCategory
        )
      ) {
        totalVideoCount += count;
      }
    });

    flatEntries.map(([flatId, flatMedias]) => {
      const flatMediasEntries = Object.entries(flatMedias);
      flatMediasEntries.map(([mediaKey, count]) => {
        if (
          [OrgFileCategory.FLAT_IMAGE].includes(mediaKey as OrgFileCategory)
        ) {
          totalImageCount += count;
        }

        if (
          [OrgFileCategory.FLAT_VIDEO].includes(mediaKey as OrgFileCategory)
        ) {
          totalVideoCount += count;
        }

        if (
          [OrgFileCategory.FLOOR_PLAN_IMAGE].includes(
            mediaKey as OrgFileCategory
          )
        ) {
          totalFloorCount += count;
        }
      });
    });
  }

  return { totalImageCount, totalVideoCount, totalFloorCount };
};
