import * as React from 'react';
import {
  Box,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import useAuthToken from '../../../store/auth/hooks/useAuthToken';
import useOrgPvtProperties, {
  OrgPvtPropertiesSortType,
} from '../../../api/orgs/useOrgPvtProperties';
import { OrgPvtPropertyV2 } from '../../../api/orgs/types';

import MyPropertiesPagination from './MyPropertiesPagination';
import MyPropertiesSorter from './MyPropertiesSorter';
import MyPropertyCardMB from './MyPropertyCardMB';
import MyPropertyCardPC from './MyPropertyCardPC';

// ========== TYPES ========== //

interface ComponentProps {
  orgId: number;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      marginBottom: 0,
      width: '100%',
    },

    navCtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    navBtnCtn: {
      display: 'flex',
      justifyContent: 'center',
      margin: `${theme.spacing(2)}px 0`,

      '& > *': {
        margin: `0 ${theme.spacing(1)}px`,
      },
    },

    gridHeading: {
      color: theme.palette.gray.dark,
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 16,
      width: '100%',
      paddingTop: theme.spacing(1),
      margin: theme.spacing(1.5, 0, 1.5, 0),
    },
  })
);

// ========== COMPONENT ========== //

export enum MyPropertiesSortType {
  NEWEST_UPLOAD = 'Newest upload',
  NAME_A_Z = 'Name (A-Z)',
  MOST_7_DAY_VIEW = 'Most 7-day view',
  MOST_AVERAGE_VIEW = 'Most average view',
  MOST_LEAD = 'Most ;ead',
}

const sortTypeMapper: Record<
  MyPropertiesSortType,
  { sortType: OrgPvtPropertiesSortType; sortDirection: 'asc' | 'desc' }
> = {
  [MyPropertiesSortType.NEWEST_UPLOAD]: {
    sortType: OrgPvtPropertiesSortType.CREATION_DATE,
    sortDirection: 'desc',
  },

  [MyPropertiesSortType.NAME_A_Z]: {
    sortType: OrgPvtPropertiesSortType.DEVELOPMENT_TITLE,
    sortDirection: 'asc',
  },

  [MyPropertiesSortType.MOST_7_DAY_VIEW]: {
    sortType: OrgPvtPropertiesSortType['7DAY_VIEW'],
    sortDirection: 'desc',
  },

  [MyPropertiesSortType.MOST_AVERAGE_VIEW]: {
    sortType: OrgPvtPropertiesSortType.AVERAGE_VIEWING_TIME,
    sortDirection: 'desc',
  },

  [MyPropertiesSortType.MOST_LEAD]: {
    sortType: OrgPvtPropertiesSortType.LEAD,
    sortDirection: 'desc',
  },
};

const defaultSortType: MyPropertiesSortType = MyPropertiesSortType.NAME_A_Z;

const DEFAULT_ITEMS_PER_PAGE = 20;

type OrgPvtPropertyWithThumbnail = Omit<OrgPvtPropertyV2, 'imgThumbnailId'> & {
  imgThumbnailId: string;
};

export default function MyProperties({ orgId }: ComponentProps) {
  const classes = useStyles();

  const authToken = useAuthToken();

  const commonApiArgs = {
    authToken,
    orgId,
  };

  const [curPage, setCurPage] = React.useState(1);
  const [sortType, setSortType] = React.useState(defaultSortType);
  const [sort, setSort] = React.useState<OrgPvtPropertiesSortType>(
    sortTypeMapper[defaultSortType].sortType
  );
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>(
    sortTypeMapper[defaultSortType].sortDirection
  );

  const { data: orgPvtProperties, status: orgPvtPropertiesStatus } =
    useOrgPvtProperties({
      ...commonApiArgs,
      page: curPage,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      sort,
      sortDirection,
    });

  const maxPage = orgPvtProperties
    ? Math.ceil(orgPvtProperties.meta.totalCount / DEFAULT_ITEMS_PER_PAGE)
    : null;

  const goToPrevPage = () => {
    setCurPage((prev) => Math.max(1, prev - 1));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const goToPage = (page: number) => {
    setCurPage(page);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const goToNextPage = () => {
    if (maxPage !== null) {
      setCurPage((prev) => Math.min(maxPage, prev + 1));
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const paginationProps = {
    totalPages: maxPage,
    currentPage: curPage,
    goToPrevPage,
    goToNextPage,
    goToPage,
  };

  const updateSort = (newSortType: MyPropertiesSortType) => {
    setSortType(newSortType);
    setSort(sortTypeMapper[newSortType].sortType);
    setSortDirection(sortTypeMapper[newSortType].sortDirection);
  };

  return (
    <>
      <Box className={classes.ctn}>
        <MyPropertiesSorter
          totalOfProperties={orgPvtProperties?.meta?.totalCount}
          sortType={sortType}
          updateSortType={updateSort}
        />
        <Hidden mdDown>
          <Grid container className={classes.gridHeading}>
            <Grid item xs={7} style={{ textAlign: 'left' }}>
              <span>
                {orgPvtProperties?.meta?.totalCount
                  ? `${orgPvtProperties?.meta?.totalCount} properties`
                  : 'No property found.'}
              </span>
            </Grid>
            <Grid container item xs={5}>
              <Grid item xs={4}>
                7-day views
              </Grid>
              <Grid item xs={4}>
                7-day leads
              </Grid>
              <Grid item xs={4}>
                Avg visit time
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        {orgPvtProperties ? (
          <>
            {orgPvtProperties.data.map((orgPvtProperty) => (
              <div key={orgPvtProperty.developmentId}>
                <Hidden lgUp>
                  <MyPropertyCardMB
                    orgId={orgId}
                    property={orgPvtProperty}
                    imgThumbnailSrc={
                      orgPvtProperty.imgThumbnail?.srcUrl ?? undefined
                    }
                  />
                </Hidden>
                <Hidden mdDown>
                  <MyPropertyCardPC
                    orgId={orgId}
                    property={orgPvtProperty}
                    mediaCounts={
                      orgPvtProperty.mediasCount ?? {
                        developmentMedias: {},
                        flatMedias: {},
                      }
                    }
                    imgThumbnailSrc={
                      orgPvtProperty.imgThumbnail?.srcUrl ?? undefined
                    }
                  />
                </Hidden>
              </div>
            ))}
          </>
        ) : orgPvtPropertiesStatus === 'loading' ? (
          <Typography>Loading properties for org...</Typography>
        ) : (
          <Typography>Could not load properties for org</Typography>
        )}
      </Box>
      <MyPropertiesPagination {...paginationProps} />
    </>
  );
}
