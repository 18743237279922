import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
export default function PropertyFloorIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="14.5" height="14.5" viewBox="0 0 14.5 14.5" {...props}>
      <g id="Group_648" data-name="Group 648" transform="translate(0.5 0.5)">
        <g id="Group_647" data-name="Group 647" transform="translate(0 0)">
          <path
            id="Path_53"
            data-name="Path 53"
            d="M137.986,109.9a.29.29,0,0,0,.29-.29v-.9a.723.723,0,0,0-.722-.722H125.5a.723.723,0,0,0-.722.722v12.056a.723.723,0,0,0,.722.722h12.056a.723.723,0,0,0,.722-.722v-8.641a.29.29,0,1,0-.581,0v1.947h-3.723a.29.29,0,1,0,0,.581H137.7v2.43h-1.319a.29.29,0,0,0,0,.581H137.7v3.1a.142.142,0,0,1-.141.141h-6.8v-3.245h2.818a.29.29,0,0,0,0-.581H128.3a.29.29,0,0,0,0,.581h1.881v3.245H125.5a.142.142,0,0,1-.141-.141v-3.1h.943a.29.29,0,1,0,0-.581h-.943v-4.263h4.413a.29.29,0,0,0,.29-.29v-1.345a.29.29,0,0,0-.581,0v1.055h-4.123v-3.529a.142.142,0,0,1,.141-.141h3.982v.817a.29.29,0,0,0,.581,0v-.817h3.622v4.037a.29.29,0,1,0,.581,0v-4.037h3.292a.142.142,0,0,1,.141.141v.9A.29.29,0,0,0,137.986,109.9Z"
            transform="translate(-124.776 -107.991)"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
