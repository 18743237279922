import * as React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Place } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import PropertyImageIcon from '../../../images/icons/property/PropertyImageIcon';
import PropertyVideoIcon from '../../../images/icons/property/PropertyVideoIcon';
import PropertyFloorIcon from '../../../images/icons/property/PropertyFloorIcon';

import { navigate } from '../../../utils/dom';
import { formatNumber } from '../../../utils/number';
import { getMediaCounts } from './helpers';

// ========== TYPES ========== //

export interface MyPropertyCardPCProps {
  orgId: number;
  property: {
    developmentId: number;
    title: string;
    postcode: string | null;
    address: string | null;
    addressLine2: string | null;
    city: string | null;
    region: string | null;
    localAuthority: string | null;
    country: string | null;
    imgThumbnailId: string | null;
    developmentAnalytic?: {
      developmentViewCount7day: number | null;
      developmentAverageEngagementTime7day: number | null;
    };
  };
  imgThumbnailSrc?: string;
  mediaCounts?: {
    developmentMedias: Record<string, number>;
    flatMedias: Record<string, Record<string, number>>;
  };
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      padding: `0 ${theme.spacing(2)}px`,
    },

    customCard: {
      boxShadow: 'none',
      borderRadius: 0,
      marginBottom: theme.spacing(2),
    },

    cardContent: {
      padding: 0,
    },

    cardInfo: {
      padding: '16px 20px',
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'space-between',
    },

    cardTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 24,
      fontWeight: 600,
      textTransform: 'uppercase',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },

    cardSubTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      marginTop: 'auto',
    },

    cardPrice: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },

    cardMedia: {
      position: 'relative',
    },

    media: {
      height: '100%',
      width: '100%',
      maxHeight: 175,
      objectFit: 'cover',
      objectPosition: 'center',
      display: 'block',
    },

    mediaTip: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      position: 'absolute',
      bottom: 0,
      height: 25,
      fontSize: 16,
      width: '100%',
    },

    icon: {
      color: theme.palette.gray.dark,
      fontSize: 18,
      verticalAlign: 'middle',
      marginLeft: -4,
    },
    upIcon: {
      transform: 'rotateZ(-90deg)',
      color: '#3258A8',
      fontSize: 18,
    },
    downIcon: {
      transform: 'rotateZ(90deg)',
      color: theme.palette.primary.main,
      fontSize: 18,
    },
    analyticsNumber: { fontWeight: theme.typography.fontWeightSemiBold },
    textIcon: {
      color: theme.palette.gray.light1,
      verticalAlign: 'middle',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      margin: '8px 0',
    },
    alignRow: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 16,
      color: theme.palette.gray.dark,
      margin: 0,
      '& svg': {
        margin: '-4px 0',
      },
    },
    reportCard: {
      backgroundColor: '#EDEDED',
      padding: '24px 0',
      fontSize: 16,
      fontFamily: theme.typography.fontFamily,
    },
    gridCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: '2px solid #FFFFFF',
      '&:last-child': {
        borderRight: 0,
      },
    },
    gridCenterBaseLine: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      '& .MuiSvgIcon-root': {
        width: 18,
        height: 18,
        marginRight: 9,
      },
    },
    gridRow: {
      margin: '0 0 8px',
    },
  })
);

// ========== COMPONENT ========== //

export default function MyPropertyCardPC({
  orgId,
  property,
  mediaCounts,
  imgThumbnailSrc,
}: MyPropertyCardPCProps) {
  const classes = useStyles();

  const addressWithPostcode = [property.address, property.postcode].join(', ');

  const handleCardClick = () => {
    void navigate(
      `/my-account/property/edit/${orgId}/${property.developmentId}/basic-information`
    );
  };

  const { totalImageCount, totalVideoCount, totalFloorCount } = getMediaCounts(
    mediaCounts?.developmentMedias,
    mediaCounts?.flatMedias
  );

  const viewCount = property.developmentAnalytic?.developmentViewCount7day;
  const showViewCount = viewCount !== null && viewCount !== undefined;

  const averageEngagementTime =
    property.developmentAnalytic?.developmentAverageEngagementTime7day;
  const showAverageEngagementTime =
    averageEngagementTime !== null && averageEngagementTime !== undefined;

  return (
    <Card className={classes.customCard}>
      <CardActionArea onClick={handleCardClick}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0}>
            <Grid item container xs={7}>
              <Grid item xs={5} className={classes.cardMedia}>
                {imgThumbnailSrc ? (
                  <img
                    className={classes.media}
                    src={imgThumbnailSrc}
                    alt={property.title}
                  />
                ) : (
                  <Skeleton variant="rect" width="auto" height={175} />
                )}
              </Grid>
              <Grid item xs={7} className={classes.cardInfo}>
                <Typography variant="h2" className={classes.cardTitle}>
                  {property.title}
                </Typography>
                <Typography className={classes.textIcon}>
                  <Place className={classes.icon} />{' '}
                  <span className={classes.textIcon}>
                    {addressWithPostcode}
                  </span>
                </Typography>
                <Grid container className={classes.gridRow}>
                  <Grid item xs={4} className={classes.gridCenterBaseLine}>
                    <PropertyImageIcon /> {totalImageCount ?? 0}
                  </Grid>
                  <Grid item xs={4} className={classes.gridCenterBaseLine}>
                    <PropertyVideoIcon /> {totalVideoCount ?? 0}
                  </Grid>
                  <Grid item xs={4} className={classes.gridCenterBaseLine}>
                    <PropertyFloorIcon /> {totalFloorCount ?? 0}
                  </Grid>
                </Grid>
                {/*<Typography*/}
                {/*  variant="subtitle1"*/}
                {/*  className={classes.cardSubTitle}*/}
                {/*>*/}
                {/*  From{' '}*/}
                {/*  <span className={classes.cardPrice}>*/}
                {/*    {formatMoney(property?.rent_pcm, 'GBP', 0)}*/}
                {/*  </span>*/}
                {/*  /month*/}
                {/*</Typography>*/}
              </Grid>
            </Grid>
            <Grid item container xs={5} className={classes.reportCard}>
              <Grid item xs={4} className={classes.gridCenter}>
                <div className={classes.alignRow}>
                  {showViewCount ? (
                    <div className={classes.analyticsNumber}>{viewCount}</div>
                  ) : (
                    'Coming soon'
                  )}
                  {/*<span className={classes.analyticsNumber}>*/}
                  {/*  32 (<ForwardIcon className={classes.upIcon} /> 29%)*/}
                  {/*</span>*/}
                  {/*<span>vs. last period</span>*/}
                </div>
              </Grid>
              <Grid item xs={4} className={classes.gridCenter}>
                <div className={classes.alignRow}>
                  {/*<span className={classes.analyticsNumber}>*/}
                  {/*  59 (<ForwardIcon className={classes.downIcon} /> 32%)*/}
                  {/*</span>*/}
                  {/*<span>vs. last period</span>*/}
                  <div className={classes.alignRow}>Coming soon</div>
                </div>
              </Grid>
              <Grid item xs={4} className={classes.gridCenter}>
                <div className={classes.alignRow}>
                  {showAverageEngagementTime ? (
                    <div className={classes.analyticsNumber}>
                      {`${formatNumber(averageEngagementTime, {
                        decimalPlaces: 2,
                      })}s`}
                    </div>
                  ) : (
                    'Coming soon'
                  )}
                  {/*<span className={classes.analyticsNumber}>*/}
                  {/*  52 (<ForwardIcon className={classes.upIcon} /> 16%)*/}
                  {/*</span>*/}
                  {/*<span>vs. last period</span>*/}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
