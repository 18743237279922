import { getLocale } from './string';

/**
 * Convert a number to a currency representation. Make sure the provided
 * currency is valid per this list:
 * https://www.currency-iso.org/en/home/tables/table-a1.html
 *
 * If the value can't be converted to a currency representation, will return the
 * value converted to string.
 */
export function formatMoney(
  amt: number,
  currency: string,
  fractionDigits = 0
): string {
  try {
    if (!amt) return '-';

    return Number(amt).toLocaleString(getLocale(), {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
  } catch (err) {
    return `${amt}`;
  }
}

/**
 * Return true if the provided value is a number.
 */
export function isValueNumber(val: any): boolean {
  return (
    val !== null &&
    val !== undefined &&
    val !== '' &&
    typeof val !== 'boolean' &&
    !Array.isArray(val) &&
    !Number.isNaN(Number(val))
  );
}

/**
 * Format a number (will use the browser's locale).
 */
export function formatNumber(
  n: number,
  { decimalPlaces }: { decimalPlaces?: number } = {}
): string {
  const locale = getLocale();

  return n.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

/**
 * Convert a number from meters to miles
 */
export function metersToMiles(meters: number): number {
  return meters * 0.000621371;
}
