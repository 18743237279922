import React from 'react';

import {
  createStyles,
  makeStyles,
  Theme,
  TextField,
  MenuItem,
  Button,
  Box,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Typography } from '../../atoms';

// ========== TYPES ========== //

interface ComponentProps {
  totalPages: number | undefined | null;
  currentPage: number | undefined | null;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  goToPage: (page: number) => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagination: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 430,
      marginLeft: 'auto',
      marginTop: theme.spacing(5),
      // marginTop: 30,
      // marginBottom: 0,
      // background: '#fff',
      // marginLeft: -32,
      // marginRight: -32,
      // fontSize: 14,
      // width: 'calc(100% + 64px)',
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#fff',
        padding: theme.spacing(1.5, 2),
        margin: '37px -16px -20px',
      },
    },
    paginationLeft: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paginationRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paginationPage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        '& .MuiTypography-root': {
          fontSize: 14,
        },
      },
    },
    pageSelect: {
      margin: theme.spacing(0, 1),
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
      },
      '& .MuiSelect-root': {
        padding: theme.spacing(0.5, 2, 0.5, 1),
        color: theme.palette.gray.contrastText,
        fontSize: 13,
        fontWeight: 700,
      },
      '& .MuiSelect-iconOutlined': {
        color: theme.palette.gray.light1,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.gray.dark,
      },
      [theme.breakpoints.down('md')]: {
        '& .MuiSelect-root': {
          fontSize: 12,
        },
      },
    },
    paginationButton: {
      textTransform: 'capitalize',
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      color: theme.palette.gray.dark,
      borderRight: `1px solid ${theme.palette.gray.light1}`,
      borderRadius: 0,
      padding: theme.spacing(0, 2, 0, 0),
      lineHeight: '20px',
      marginLeft: -8,
      minWidth: 'auto',
      '& .MuiSvgIcon-root': {
        marginTop: -2,
        marginBottom: -2,
        marginRight: 6,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        marginLeft: 0,
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
    },
    paginationNext: {
      borderLeft: `1px solid ${theme.palette.gray.light1}`,
      borderRight: 0,
      padding: theme.spacing(0, 0, 0, 2),
      marginLeft: 0,
      marginRight: -8,
      '& .MuiButton-label svg': {
        marginLeft: 6,
        marginRight: 0,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
      },
    },
    pageSelectItem: {
      margin: '0 6px',
      padding: theme.spacing(0.5),
      color: theme.palette.gray.main,
      justifyContent: 'center',
      width: 32,
      minHeight: 'auto',
      '&.Mui-selected:hover': {
        backgroundColor: '#f3f3f3',
      },
      [theme.breakpoints.down('md')]: {
        width: 29,
        height: 29,
        fontSize: 14,
      },
    },
  })
);

// ========== COMPONENT ========== //

export default function MyPropertiesPagination({
  totalPages,
  currentPage,
  goToNextPage,
  goToPrevPage,
  goToPage,
}: ComponentProps) {
  const classes = useStyles();

  if (!totalPages) return null;

  function generatePageMenuItem(totalPages: number): React.ReactNode[] {
    const menuItems: React.ReactNode[] = [];
    for (let page = 1; page <= totalPages; page++) {
      menuItems.push(
        <MenuItem key={page} value={page} className={classes.pageSelectItem}>
          {page}
        </MenuItem>
      );
    }
    return menuItems;
  }

  const pageItems = generatePageMenuItem(totalPages);

  return (
    <Box className={classes.pagination}>
      <Box className={classes.paginationLeft}>
        <Button
          className={classes.paginationButton}
          onClick={goToPrevPage}
          disabled={currentPage == 1}
        >
          <ArrowLeftIcon />
          Previous
        </Button>
      </Box>
      <Box className={classes.paginationPage}>
        <Typography component="span" color="gray" colorVariant="dark">
          Page
        </Typography>
        <TextField
          id="pagination"
          select
          variant="outlined"
          className={classes.pageSelect}
          defaultValue={currentPage}
          size="small"
          onChange={(event) => {
            goToPage(Number(event.target.value));
          }}
        >
          {pageItems}
        </TextField>
        <Typography component="span" color="gray" colorVariant="dark">
          of {totalPages}
        </Typography>
      </Box>
      <Box className={classes.paginationRight}>
        <Button
          className={`${classes.paginationButton} ${classes.paginationNext}`}
          onClick={goToNextPage}
          disabled={currentPage == totalPages}
        >
          Next
          <ArrowRightIcon />
        </Button>
      </Box>
    </Box>
  );
}
